/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/moment@2.29.4/moment.min.js
 * - /npm/jquery-validation@1.19.3/dist/jquery.validate.min.js
 * - /npm/cleave.js@1.6.0/dist/cleave.min.js
 * - /npm/cleave.js@1.6.0/dist/addons/cleave-phone.nz.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
